import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  Paper,
  Typography,
  IconButton,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  Badge,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import config from '../../config/config.json';
import { DataGrid } from '@mui/x-data-grid';
import CustomInput from 'components/CustomInput/CustomInput.js';
import InputAdornment from '@mui/material/InputAdornment';
import TextFields from '@mui/icons-material/TextFields';
import chestImg from '../../assets/img/d2rchest.png';
import toast from 'react-hot-toast';

interface MuleBubbleProps {
  hasBottomBubble?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: 9999,
    pointerEvents: 'none',
  },
  muleContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    zIndex: 99999,
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    minWidth: '300px',
    '@media (min-width: 600px)': {
      minWidth: '400px',
    },
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  contentWrapper: {
    width: '100%',
    maxHeight: 'calc(100vh - 180px)',
    marginBottom: '8px',
    position: 'relative',
    bottom: 'auto',
    overflowY: 'auto',
  },
  muleContent: {
    padding: '16px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  headerBox: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  gameItem: {
    padding: '8px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  muleButton: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&.open': {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    width: 56,
    height: 56,
    borderRadius: '50%',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      right: 4,
      top: 4,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  },
}));

export default function MuleBubble({ hasBottomBubble = true }: MuleBubbleProps) {
  const classes = useStyles({ hasBottomBubble });
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const sessionToken = localStorage.getItem('session_token');
  const defaultMuleRealm = localStorage.getItem('default_realm') || 'europe';
  const [realmMuling, setRealmMuling] = useState(defaultMuleRealm);
  const queryClient = useQueryClient();
  const showMuleBubble = parseInt(localStorage.getItem('show_mule_bubble') || '1', 10) === 1;

  // Form states
  const [muleGameName, setMuleGameName] = useState('');
  const [muleGamePassword, setMuleGamePassword] = useState('');
  const [fiatOnlyMuling, setFiatOnlyMuling] = useState(0);
  const [selectedMuleGameIDs, setSelectedMuleGameIDs] = useState<number[]>([]);

  // Query for user type
  const { data: userTypeData } = useQuery({
    queryKey: ['userType'],
    queryFn: async () => {
      if (!sessionToken) return null;
      const response = await axios.get(`${config.backend_base_url}user/type`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data;
    },
    enabled: !!sessionToken,
  });

  // Update node name and canMule when user type data changes
  useEffect(() => {
    if (userTypeData?.node_name) {
      localStorage.setItem('nodename', userTypeData.node_name);
    }
  }, [userTypeData]);

  const canMule = true;

  // Query for mule games
  const { data: muleGames = [] } = useQuery({
    queryKey: ['muleGames'],
    queryFn: async () => {
      if (!sessionToken) return [];
      const response = await axios.get(`${config.backend_base_url}mygames`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data || [];
    },
    enabled: !!sessionToken,
  });

  // Query for mule char amount
  const { data: muleGameAndCharAmount = { free_chars: 0, max_games: 0 } } = useQuery({
    queryKey: ['muleCharAmount'],
    queryFn: async () => {
      if (!sessionToken) return { free_chars: 0, max_games: 0 };
      const response = await axios.get(`${config.backend_base_url}mls/mule/char/amount`, {
        headers: { 'session-token': sessionToken },
      });
      return response.data || { free_chars: 0, max_games: 0 };
    },
    enabled: !!sessionToken && isOpen,
  });

  // Query for admin settings
  useQuery({
    queryKey: ['adminSettings'],
    queryFn: async () => {
      if (!sessionToken) return null;
      const response = await axios.get(`${config.backend_base_url}admin/settings`, {
        headers: { 'session-token': sessionToken },
      });
      if (response.data) {
        if (response.data.mule_game_name && muleGameName === '') {
          setMuleGameName(response.data.mule_game_name);
        }
        if (response.data.mule_game_password && muleGamePassword === '') {
          setMuleGamePassword(response.data.mule_game_password);
        }
      }
      return response.data;
    },
    enabled: !!sessionToken && isOpen,
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const openMuleGameForAdmin = async () => {
    if (!sessionToken) return;

    let checkFailed = false;
    if (muleGameName.length < 1) {
      toast.error('You must use a custom Gamename');
      checkFailed = true;
    }
    if (muleGamePassword.length < 1) {
      toast.error('You must use a custom Gamepassword');
      checkFailed = true;
    }
    if (checkFailed) return;

    try {
      await axios.post(
        `${config.backend_base_url}game/open`,
        {
          fiat_only: fiatOnlyMuling,
          realm: realmMuling,
          game_name: muleGameName,
          game_password: muleGamePassword,
        },
        { headers: { 'session-token': sessionToken } }
      );

      toast.success('Successfully opened Game.');
      // Invalidate and refetch mule games
      queryClient.invalidateQueries({ queryKey: ['muleGames'] });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data || 'Failed to open game');
      }
    }
  };

  const closeMuleGames = async () => {
    if (!sessionToken) return;

    try {
      await axios.post(
        `${config.backend_base_url}games/close`,
        {},
        { headers: { 'session-token': sessionToken } }
      );

      toast.success('Successfully closed Games.');
      // Invalidate and refetch mule games
      queryClient.invalidateQueries({ queryKey: ['muleGames'] });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.Message || 'Failed to close games');
      }
    }
  };

  const closeSelectedMuleGames = async () => {
    if (!sessionToken || selectedMuleGameIDs.length === 0) return;

    try {
      await axios.post(
        `${config.backend_base_url}game/close`,
        { game_ids: selectedMuleGameIDs },
        { headers: { 'session-token': sessionToken } }
      );

      toast.success('Successfully closed Games.');
      setSelectedMuleGameIDs([]);
      // Invalidate and refetch mule games
      queryClient.invalidateQueries({ queryKey: ['muleGames'] });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.Message || 'Failed to close selected games');
      }
    }
  };

  const d2rColumns = [
    {
      field: 'name',
      headerName: t('search.columns.gameName'),
      flex: 4,
    },
    {
      field: 'password',
      headerName: t('search.columns.password'),
      flex: 4,
    },
    {
      field: 'node_name',
      headerName: 'Node',
      flex: 4,
    },
    {
      field: 'realm',
      headerName: 'Realm',
      flex: 4,
    },
    {
      field: 'fiat_only',
      headerName: 'Fiat Only',
      flex: 1,
    },
    {
      field: 'permanent',
      headerName: 'Permanent',
      flex: 1,
    },
  ];

  if (!showMuleBubble || !sessionToken || !canMule) {
    return null;
  }

  return (
    <Box className={classes.root} sx={{ bottom: hasBottomBubble ? '88px' : '16px' }}>
      <Box className={classes.muleContainer}>
        {isOpen && (
          <Paper className={classes.contentWrapper} elevation={4}>
            <Box className={classes.muleContent}>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2,
                      }}
                    >
                      <Typography>
                        {t('game.mule.serviceCharsAvailable', {
                          count: muleGameAndCharAmount.free_chars,
                          maxGames: muleGameAndCharAmount.max_games,
                        })}
                      </Typography>
                      <IconButton size="small" onClick={handleToggle} aria-label="close mule games">
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ width: '100%', mb: 2 }}>
                      <DataGrid
                        autoHeight
                        columns={d2rColumns}
                        rows={muleGames}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        onRowSelectionModelChange={(params) => {
                          setSelectedMuleGameIDs(params as number[]);
                        }}
                        sx={{
                          '& .MuiDataGrid-root': {
                            border: 'none',
                          },
                          '& .MuiDataGrid-cell': {
                            borderBottom: 1,
                            borderColor: 'divider',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'background.paper',
                            borderBottom: 2,
                            borderColor: 'divider',
                          },
                          '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: 'background.paper',
                          },
                          width: '100%',
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={fiatOnlyMuling}
                        onChange={(e) => setFiatOnlyMuling(e.target.value as number)}
                        sx={{
                          width: '100%',
                          backgroundColor: 'background.paper',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'divider',
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              backgroundColor: 'background.paper',
                            },
                          },
                          sx: {
                            zIndex: 10001,
                          },
                        }}
                      >
                        <MenuItem value={0}>{t('game.mule.fiatAndFg')}</MenuItem>
                        <MenuItem value={1}>{t('game.mule.fiatOnly')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInput
                      labelText={t('search.game.name')}
                      id="gameName"
                      value={muleGameName}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      onChange={(e) => setMuleGameName(e.target.value)}
                      inputProps={{
                        type: 'text',
                        endAdornment: (
                          <InputAdornment position="end">
                            <TextFields />
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomInput
                      labelText={t('search.game.password')}
                      id="gamePassword"
                      value={muleGamePassword}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      onChange={(e) => setMuleGamePassword(e.target.value)}
                      inputProps={{
                        type: 'text',
                        endAdornment: (
                          <InputAdornment position="end">
                            <TextFields />
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={realmMuling}
                        onChange={(e) => {
                          setRealmMuling(e.target.value as string);
                          localStorage.setItem('default_realm', e.target.value as string);
                        }}
                        sx={{
                          width: '100%',
                          backgroundColor: 'background.paper',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'divider',
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              backgroundColor: 'background.paper',
                            },
                          },
                          sx: {
                            zIndex: 10001,
                          },
                        }}
                      >
                        <MenuItem value="americas">{t('search.game.realm.americas')}</MenuItem>
                        <MenuItem value="europe">{t('search.game.realm.europe')}</MenuItem>
                        <MenuItem value="asia">{t('search.game.realm.asia')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Button
                        color="primary"
                        onClick={openMuleGameForAdmin}
                        disabled={!canMule}
                        sx={{
                          opacity: canMule ? 1 : 0.5,
                          cursor: canMule ? 'pointer' : 'not-allowed',
                        }}
                      >
                        <img
                          style={{ width: '16px', verticalAlign: 'middle', marginRight: '8px' }}
                          src={chestImg}
                          alt={t('game.mule.openGame')}
                        />
                        {t('game.mule.openGame')}
                      </Button>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Button
                        color="secondary"
                        disabled={selectedMuleGameIDs.length === 0}
                        onClick={closeSelectedMuleGames}
                      >
                        ❌{t('game.mule.closeSelected')}
                      </Button>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Button color="secondary" onClick={closeMuleGames}>
                        ❌{t('game.mule.closeAll')}
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        )}
        <Badge badgeContent={muleGames.length} color="primary" className={classes.badge} max={99}>
          <IconButton
            className={`${classes.muleButton} ${isOpen ? 'open' : ''}`}
            onClick={handleToggle}
            aria-label={isOpen ? 'close mule games' : 'show mule games'}
            size="large"
          >
            <img
              style={{ width: '32px', verticalAlign: 'middle' }}
              src={chestImg}
              alt={t('game.mule.openGame')}
            />
          </IconButton>
        </Badge>
      </Box>
    </Box>
  );
}
