import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Collapse, IconButton, Paper, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  helpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    minWidth: '300px',
    '@media (min-width: 600px)': {
      minWidth: '400px',
    },
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  helpContent: {
    padding: '16px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  warningText: {
    color: theme.palette.error.main,
    marginTop: '8px',
    fontWeight: 'bold',
  },
  helpButton: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&.open': {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    width: 56,
    height: 56,
    borderRadius: '50%',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
}));

interface HelpBubbleProps {
  defaultText?: string;
  pageKey: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HelpBubble({ defaultText, pageKey, isOpen, setIsOpen }: HelpBubbleProps) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const isEnglish = i18n.language === 'en' || i18n.language.startsWith('en-');
  const showHelpBubble = parseInt(localStorage.getItem('show_help_bubble') || '1', 10) === 1;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (!showHelpBubble) {
    return null;
  }

  const zIndex = isOpen ? '99999' : '0';
  const height = isOpen ? 'unset' : '0';

  return (
    <Box className={classes.helpContainer} style={{ zIndex, height }}>
      <Collapse in={isOpen} style={{ width: '100%' }}>
        <Paper className={classes.helpContent} elevation={4}>
          {!isEnglish && (
            <Typography variant="body2" className={classes.warningText}>
              <Trans i18nKey="common.englishOnlyWarning" />
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="body1" component="div" style={{ flex: 1 }}>
              <Trans i18nKey={`help.${pageKey}`} defaults={defaultText} />
            </Typography>
            <IconButton
              size="small"
              onClick={handleToggle}
              sx={{ ml: 1, mt: -0.5 }}
              aria-label="close help"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Paper>
      </Collapse>
      <IconButton
        className={`${classes.helpButton} ${isOpen ? 'open' : ''}`}
        onClick={handleToggle}
        aria-label={isOpen ? 'close help' : 'help'}
        size="large"
      >
        <HelpOutlineIcon />
      </IconButton>
    </Box>
  );
}
