import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Button,
  IconButton,
  Tooltip,
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import NavPills from '../../components/NavPills/NavPills.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery, useMutation, useQueryClient, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config/config.json';
import toast from 'react-hot-toast';
import BubbleContainer from 'components/BubbleContainer/BubbleContainer';
import TradeListFilters from '../../components/TradeListFilters/TradeListFilters';
import TradeListGenerateControls from '../../components/TradeListGenerateControls/TradeListGenerateControls';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface NavPillsTab {
  tabName: string;
  tabButton: string | React.ReactNode;
  tabContent: React.ReactNode;
  tabIcon?: React.ComponentType;
}

interface TopicTextList {
  id: number;
  user_id: number;
  search_term: string;
  title: string;
  group: string;
  search_count: number;
  price?: string;
}

interface AddTopicListPayload {
  search_term: string;
  title: string;
  group: string;
}

interface UpdateTopicListPayload {
  id: number;
  group: string;
}

interface SavedSearch {
  id: number;
  user_id: number;
  search_term: string;
  search_name: string;
  search_count: number;
  eth_items_filter: number;
  new_items_filter: number;
  rarity_filter_id: number;
  type_filter_id: number;
  unid_filter: number;
  stat_filters: any;
  stats_filters_string: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'relative',
    zIndex: 3,
    paddingTop: '0',
    width: '100%',
  },
  mainContainer: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
    width: '100%',
  },
  grid: {
    marginBottom: '10px',
  },
  toggleButton: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
    color: theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
    borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : undefined,
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : undefined,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : undefined,
    },
  },
  toggleButtonActive: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : undefined,
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
    borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px',
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : undefined,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
      borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : undefined,
    },
  },
}));

const api = {
  getTopicLists: async (): Promise<TopicTextList[]> => {
    try {
      const response = await axios.get(`${config.backend_base_url}ml/tradelist/mine`, {
        headers: { 'session-token': localStorage.getItem('session_token') },
      });
      return response.data || [];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },

  getDefaultLists: async (group?: string): Promise<TopicTextList[]> => {
    try {
      const url = `${config.backend_base_url}items/common/${group}`;
      const response = await axios.get(url, {
        headers: { 'session-token': localStorage.getItem('session_token') },
      });
      return response.data || [];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },

  getCommonItemGroups: async (): Promise<string[]> => {
    try {
      const response = await axios.get(`${config.backend_base_url}items/common/groups`, {
        headers: { 'session-token': localStorage.getItem('session_token') },
      });
      return response.data || [];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },

  getSavedSearches: async (): Promise<SavedSearch[]> => {
    try {
      const response = await axios.get(`${config.backend_base_url}ml/search/mine`, {
        headers: { 'session-token': localStorage.getItem('session_token') },
      });
      return response.data || [];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },

  deleteTopicLists: async (ids: number[]): Promise<void> => {
    try {
      await axios.post(
        `${config.backend_base_url}ml/tradelist/delete`,
        { ids },
        { headers: { 'session-token': localStorage.getItem('session_token') } }
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },

  addTopicList: async (data: AddTopicListPayload): Promise<void> => {
    try {
      await axios.post(`${config.backend_base_url}ml/tradelist/add`, data, {
        headers: { 'session-token': localStorage.getItem('session_token') },
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },

  updateTopicList: async (data: UpdateTopicListPayload): Promise<void> => {
    try {
      await axios.post(`${config.backend_base_url}ml/tradelist/update`, data, {
        headers: { 'session-token': localStorage.getItem('session_token') },
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      }
      throw error;
    }
  },
};

function useTopicLists() {
  return useQuery({
    queryKey: ['topicLists'],
    queryFn: api.getTopicLists,
  });
}

function useCommonItemGroups() {
  return useQuery<string[]>({
    queryKey: ['commonItemGroups'],
    queryFn: () => api.getCommonItemGroups(),
  });
}

function useDefaultLists(selectedGroup?: string) {
  return useQuery<TopicTextList[]>({
    queryKey: ['defaultLists', selectedGroup],
    queryFn: async ({ queryKey }) => {
      const [, group] = queryKey;
      return api.getDefaultLists(group as string | undefined);
    },
    enabled: selectedGroup !== undefined, // Only run query when we have a group
  });
}

function useSavedSearches() {
  return useQuery({
    queryKey: ['savedSearches'],
    queryFn: api.getSavedSearches,
  });
}

function useDeleteTopicLists(t: (key: string) => string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: api.deleteTopicLists,
    onSuccess: () => {
      toast.success(t('tradeLists.savedSearches.deleteSuccess'));
      queryClient.invalidateQueries({ queryKey: ['topicLists'] });
    },
    onError: () => {
      toast.error(t('tradeLists.savedSearches.deleteError'));
    },
  });
}

function useAddTopicList(
  t: (key: string) => string
): UseMutationResult<void, Error, AddTopicListPayload> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: api.addTopicList,
    onSuccess: () => {
      toast.success(t('tradeLists.savedSearches.saveSuccess'));
      queryClient.invalidateQueries({ queryKey: ['topicLists'] });
    },
    onError: () => {
      toast.error(t('tradeLists.savedSearches.saveError'));
    },
  });
}

function useUpdateTopicList(t: (key: string) => string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: api.updateTopicList,
    onSuccess: () => {
      toast.success(t('tradeLists.savedSearches.updateSuccess'));
      queryClient.invalidateQueries({ queryKey: ['topicLists'] });
    },
    onError: () => {
      toast.error(t('tradeLists.savedSearches.updateError'));
    },
  });
}

export function TradeListsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('navigation.tradeLists'));

  useEffect(() => {
    const sessionToken = localStorage.getItem('session_token');
    if (!sessionToken) {
      navigate('/login');
      return;
    }
  }, [navigate]);

  const [showHelpBubble] = useState<number>(
    parseInt(localStorage.getItem('show_trade_lists_help_bubble') || '1', 10)
  );
  const [selectedTopicListIDs, setSelectedTopicListIDs] = useState<number[]>([]);
  const [selectedDefaultListIDs, setSelectedDefaultListIDs] = useState<number[]>([]);
  const [selectedSavedSearchIDs, setSelectedSavedSearchIDs] = useState<number[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [group, setGroup] = useState('');
  const [maxCountDisplayed, setMaxCountDisplayed] = useState<number>(10);
  const [displayPrices, setDisplayPrices] = useState<boolean>(true);
  const [includeZeroStock, setIncludeZeroStock] = useState<boolean>(false);
  const [showAmount, setShowAmount] = useState<boolean>(true);
  const [generatedText, setGeneratedText] = useState<string>('');
  const [counterSymbol, setCounterSymbol] = useState<string>('x');
  const [priceSeparator, setPriceSeparator] = useState<string>('=');
  const [displayGroups, setDisplayGroups] = useState<boolean>(true);
  const [hideZeroPrefix, setHideZeroPrefix] = useState<boolean>(false);
  const [prependIntroText, setPrependIntroText] = useState<boolean>(true);

  const { data: topicLists = [], isLoading: isTopicListsLoading } = useTopicLists();
  const { data: commonItemGroups = [], isLoading: isCommonItemGroupsLoading } =
    useCommonItemGroups();
  const { data: defaultLists = [], isLoading: isDefaultListsLoading } =
    useDefaultLists(selectedGroup);
  const { data: savedSearches = [], isLoading: isSavedSearchesLoading } = useSavedSearches();
  const deleteTopicListsMutation = useDeleteTopicLists(t);
  const addTopicListMutation = useAddTopicList(t);
  const updateTopicListMutation = useUpdateTopicList(t);

  // Initialize selection state when data is loaded
  useEffect(() => {
    if (savedSearches.length > 0 && selectedSavedSearchIDs.length === 0) {
      setSelectedSavedSearchIDs(savedSearches.map((row) => row.id));
    }
    if (topicLists.length > 0 && selectedTopicListIDs.length === 0) {
      setSelectedTopicListIDs(topicLists.map((row) => row.id));
    }
    if (defaultLists.length > 0 && selectedDefaultListIDs.length === 0) {
      setSelectedDefaultListIDs(defaultLists.map((row) => row.id));
    }
  }, [
    savedSearches,
    topicLists,
    defaultLists,
    selectedSavedSearchIDs.length,
    selectedTopicListIDs.length,
    selectedDefaultListIDs.length,
  ]);

  // Set first group as default when groups are loaded
  useEffect(() => {
    if (!isCommonItemGroupsLoading && commonItemGroups.length > 0 && selectedGroup === undefined) {
      setSelectedGroup(commonItemGroups[0]);
    }
  }, [commonItemGroups, isCommonItemGroupsLoading, selectedGroup]);

  const handleSelectionChange = (params: any, type: 'saved' | 'trade' | 'default') => {
    const selectedIds = params as number[];
    switch (type) {
      case 'saved':
        setSelectedSavedSearchIDs(selectedIds);
        break;
      case 'trade':
        setSelectedTopicListIDs(selectedIds);
        break;
      case 'default':
        setSelectedDefaultListIDs(selectedIds);
        break;
    }
  };

  const handleUpdateCell = (params: any) => {
    const { id, field, value } = params;
    if (field === 'group') {
      toast.loading(t('tradeLists.form.updating'), { id: 'updateToast' });
      updateTopicListMutation.mutate(
        {
          id: id as number,
          group: value,
        },
        {
          onSuccess: () => {
            toast.dismiss('updateToast');
          },
          onError: () => {
            toast.dismiss('updateToast');
          },
        }
      );
    }
  };

  const handleCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(t('tradeLists.generateList.copiedToClipboard'));
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error(t('tradeLists.generateList.clipboardError'));
    }
  };

  const renderSearchTermCell = (params: any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Tooltip title={t('tradeLists.generateList.copiedToClipboard')} placement="top">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleCopyToClipboard(params.value);
            }}
            size="small"
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {params.value}
      </Box>
    );
  };

  const topicListColumns: GridColDef[] = [
    {
      field: 'search_count',
      headerName: t('tradeLists.columns.count'),
      flex: 0.7,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'title',
      headerName: t('tradeLists.columns.name'),
      flex: 2,
    },
    {
      field: 'search_term',
      headerName: t('tradeLists.columns.term'),
      flex: 3,
      renderCell: renderSearchTermCell,
    },
    {
      field: 'price',
      headerName: t('tradeLists.columns.price'),
      flex: 1,
    },
    {
      field: 'group',
      headerName: t('tradeLists.columns.group'),
      flex: 1,
      editable: true,
    },
  ];

  const savedSearchColumns: GridColDef[] = [
    {
      field: 'search_count',
      headerName: t('tradeLists.columns.count'),
      flex: 0.7,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'search_name',
      headerName: t('tradeLists.columns.name'),
      flex: 2,
    },
    {
      field: 'search_term',
      headerName: t('tradeLists.columns.term'),
      flex: 3,
      renderCell: renderSearchTermCell,
    },
  ];

  const handleDeleteSelectedLists = () => {
    toast.loading(t('tradeLists.form.deleting'), { id: 'deleteToast' });
    deleteTopicListsMutation.mutate(selectedTopicListIDs, {
      onSuccess: () => {
        toast.dismiss('deleteToast');
      },
      onError: () => {
        toast.dismiss('deleteToast');
      },
    });
  };

  const handleSaveNewList = () => {
    if (!searchTerm || !name || !group) {
      toast.error(t('tradeLists.form.missingFields'));
      return;
    }

    toast.loading(t('tradeLists.savedSearches.creating'), { id: 'saveToast' });
    addTopicListMutation.mutate(
      {
        search_term: searchTerm,
        title: name,
        group: group,
      },
      {
        onSuccess: () => {
          setSearchTerm('');
          setName('');
          setGroup('');
          toast.dismiss('saveToast');
        },
        onError: () => {
          toast.dismiss('saveToast');
        },
      }
    );
  };

  const generateTradeListText = (
    lists: (TopicTextList | SavedSearch)[],
    isFromSavedSearch = false
  ) => {
    if (lists.length === 0) {
      toast.error(t('tradeLists.generateList.noSelection'));
      return;
    }

    const filteredLists = includeZeroStock ? lists : lists.filter((list) => list.search_count > 0);

    // Sort and group the lists
    const sortedLists = [...filteredLists].sort((a, b) => {
      if (isFromSavedSearch) {
        return (a as SavedSearch).search_name.localeCompare((b as SavedSearch).search_name);
      } else {
        const aList = a as TopicTextList;
        const bList = b as TopicTextList;
        const groupCompare = aList.group.localeCompare(bList.group);
        return groupCompare !== 0 ? groupCompare : aList.title.localeCompare(bList.title);
      }
    });

    let currentGroup = '';
    const text = sortedLists
      .map((list, index) => {
        const isList = !isFromSavedSearch;
        if (!isList) {
          return generateListText(list as SavedSearch, true);
        }

        const typedList = list as TopicTextList;
        let output = '';

        // Add group header if group has changed
        if (isList && displayGroups && typedList.group !== currentGroup) {
          currentGroup = typedList.group;
          if (currentGroup.trim()) {
            output = (index === 0 ? '' : '\n') + `${currentGroup}\n`;
          }
        }

        output += generateListText(list, false);
        return output;
      })
      .join('\n');

    const introText =
      'americas/asia/europe are ONLINE\n\nCTRL + F to find your iso\nPM me if you have other iso\n\nFeel free to make counter offers\nPack prices negotiable\n\n';
    const finalText = prependIntroText ? introText + text : text;
    setGeneratedText(finalText);
    navigator.clipboard
      .writeText(finalText)
      .then(() => {
        toast.success(t('tradeLists.generateList.copiedToClipboard'));
      })
      .catch(() => {
        toast.error(t('tradeLists.generateList.clipboardError'));
      });
  };

  const generateListText = (
    list: TopicTextList | SavedSearch,
    isFromSavedSearch: boolean
  ): string => {
    const count =
      maxCountDisplayed > 0 && list.search_count > maxCountDisplayed
        ? maxCountDisplayed
        : list.search_count;
    const amountText = showAmount
      ? hideZeroPrefix && count === 0
        ? ''
        : `${count}${counterSymbol} `
      : '';
    const name = isFromSavedSearch
      ? (list as SavedSearch).search_name
      : (list as TopicTextList).title;
    const baseText = `${amountText}${name}`;

    // Skip price and 'each' text if price is N/A
    if (!isFromSavedSearch) {
      const price = (list as TopicTextList).price;
      if (price && price.toLowerCase() !== 'n/a') {
        const priceText = displayPrices ? ` ${priceSeparator} ${price}` : '';
        const eachText = displayPrices && count > 1 ? ' each' : '';
        return `${baseText}${priceText}${eachText}`;
      }
    }

    return baseText;
  };

  const handleGenerateTradeList = () => {
    const selectedLists = topicLists.filter((list) => selectedTopicListIDs.includes(list.id));
    generateTradeListText(selectedLists, false);
  };

  const handleGenerateSavedSearches = () => {
    const selectedLists = savedSearches.filter((list) => selectedSavedSearchIDs.includes(list.id));
    generateTradeListText(selectedLists, true);
  };

  const handleGenerateDefaultList = () => {
    const selectedLists = defaultLists.filter((list) => selectedDefaultListIDs.includes(list.id));
    generateTradeListText(selectedLists, false);
  };

  const handleGroupChange = (_event: React.SyntheticEvent, newValue: string | null) => {
    setSelectedGroup(newValue || undefined);
    setSelectedDefaultListIDs([]);
  };

  const tabs: NavPillsTab[] = [
    {
      tabName: 'default-lists',
      tabButton: t('tradeLists.defaultLists.tabTitle') + ` (${defaultLists.length})`,
      tabContent: (
        <Box>
          <GridContainer spacing={1}>
            <GridItem xs={12}>
              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={selectedGroup || null}
                    onChange={handleGroupChange}
                    options={commonItemGroups}
                    renderInput={(params) => (
                      <TextField {...params} label={t('tradeLists.defaultLists.selectGroup')} />
                    )}
                    disabled={isCommonItemGroupsLoading}
                    loading={isCommonItemGroupsLoading}
                    loadingText={t('common.loading.fetchingData')}
                    noOptionsText={t('common.errors.noDataFound')}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                </FormControl>
              </Box>
            </GridItem>
            <GridItem xs={12}>
              <TradeListGenerateControls
                maxCountDisplayed={maxCountDisplayed}
                displayPrices={displayPrices}
                showAmount={showAmount}
                includeZeroStock={includeZeroStock}
                hideZeroPrefix={hideZeroPrefix}
                generatedText={generatedText}
                showGroupOptions={true}
                displayGroups={displayGroups}
                counterSymbol={counterSymbol}
                priceSeparator={priceSeparator}
                prependIntroText={prependIntroText}
                onMaxCountChange={setMaxCountDisplayed}
                onDisplayPricesChange={setDisplayPrices}
                onShowAmountChange={setShowAmount}
                onIncludeZeroStockChange={setIncludeZeroStock}
                onHideZeroPrefixChange={setHideZeroPrefix}
                onDisplayGroupsChange={setDisplayGroups}
                onCounterSymbolChange={setCounterSymbol}
                onPriceSeparatorChange={setPriceSeparator}
                onPrependIntroTextChange={setPrependIntroText}
                onGenerateClick={handleGenerateDefaultList}
              />
            </GridItem>
            <GridItem xs={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={defaultLists}
                  columns={topicListColumns}
                  checkboxSelection
                  loading={isDefaultListsLoading}
                  rowSelectionModel={selectedDefaultListIDs}
                  onRowSelectionModelChange={(params) => handleSelectionChange(params, 'default')}
                />
              </div>
            </GridItem>
          </GridContainer>
        </Box>
      ),
    },
    {
      tabName: 'trade-lists',
      tabButton: t('tradeLists.tabTitle') + ` (${topicLists.length})`,
      tabContent: (
        <Box>
          <GridContainer spacing={1}>
            <GridItem xs={12}>
              <TradeListGenerateControls
                maxCountDisplayed={maxCountDisplayed}
                displayPrices={displayPrices}
                showAmount={showAmount}
                includeZeroStock={includeZeroStock}
                hideZeroPrefix={hideZeroPrefix}
                generatedText={generatedText}
                counterSymbol={counterSymbol}
                priceSeparator={priceSeparator}
                showGroupOptions={true}
                displayGroups={displayGroups}
                prependIntroText={prependIntroText}
                onMaxCountChange={setMaxCountDisplayed}
                onDisplayPricesChange={setDisplayPrices}
                onShowAmountChange={setShowAmount}
                onIncludeZeroStockChange={setIncludeZeroStock}
                onHideZeroPrefixChange={setHideZeroPrefix}
                onCounterSymbolChange={setCounterSymbol}
                onPriceSeparatorChange={setPriceSeparator}
                onDisplayGroupsChange={setDisplayGroups}
                onPrependIntroTextChange={setPrependIntroText}
                onGenerateClick={handleGenerateTradeList}
              />
            </GridItem>
            <GridItem xs={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={topicLists}
                  columns={topicListColumns}
                  checkboxSelection
                  loading={isTopicListsLoading}
                  rowSelectionModel={selectedTopicListIDs}
                  onRowSelectionModelChange={(params) => handleSelectionChange(params, 'trade')}
                  processRowUpdate={(newRow) => {
                    handleUpdateCell(newRow);
                    return newRow;
                  }}
                  onProcessRowUpdateError={() => {
                    toast.error(t('tradeLists.form.updateError'));
                  }}
                />
              </div>
            </GridItem>
            <GridItem xs={12}>
              <Button
                color="error"
                variant="contained"
                fullWidth={true}
                disabled={selectedTopicListIDs.length === 0}
                onClick={handleDeleteSelectedLists}
              >
                ❌ {t('tradeLists.form.deleteButton')}
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <TradeListFilters
                searchTerm={searchTerm}
                title={name}
                group={group}
                onSearchTermChange={setSearchTerm}
                onTitleChange={setName}
                onGroupChange={setGroup}
                onCreateList={handleSaveNewList}
              />
            </GridItem>
          </GridContainer>
        </Box>
      ),
    },
    {
      tabName: 'saved-searches',
      tabButton: t('savedSearches.tabTitle') + ` (${savedSearches.length})`,
      tabContent: (
        <Box>
          <GridContainer spacing={1}>
            <GridItem xs={12}>
              <TradeListGenerateControls
                maxCountDisplayed={maxCountDisplayed}
                displayPrices={displayPrices}
                showAmount={showAmount}
                includeZeroStock={includeZeroStock}
                hideZeroPrefix={hideZeroPrefix}
                generatedText={generatedText}
                showPriceOptions={false}
                counterSymbol={counterSymbol}
                priceSeparator={priceSeparator}
                prependIntroText={prependIntroText}
                onMaxCountChange={setMaxCountDisplayed}
                onDisplayPricesChange={setDisplayPrices}
                onShowAmountChange={setShowAmount}
                onIncludeZeroStockChange={setIncludeZeroStock}
                onHideZeroPrefixChange={setHideZeroPrefix}
                onCounterSymbolChange={setCounterSymbol}
                onPriceSeparatorChange={setPriceSeparator}
                onPrependIntroTextChange={setPrependIntroText}
                onGenerateClick={handleGenerateSavedSearches}
              />
            </GridItem>
            <GridItem xs={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={savedSearches}
                  columns={savedSearchColumns}
                  checkboxSelection
                  loading={isSavedSearchesLoading}
                  rowSelectionModel={selectedSavedSearchIDs}
                  onRowSelectionModelChange={(params) => handleSelectionChange(params, 'saved')}
                />
              </div>
            </GridItem>
          </GridContainer>
        </Box>
      ),
    },
  ];

  return (
    <Container maxWidth={false} className={classes.container}>
      <NavPills
        alignCenter
        color="primary"
        tabs={tabs.map((tab) => ({
          ...tab,
          tabContent: <Box className={classes.mainContainer}>{tab.tabContent}</Box>,
        }))}
        queryKey="view"
      />
      <BubbleContainer
        pageKey="tradeLists"
        helpText={t('tradeLists.help.content')}
        hideHelpBubble={!showHelpBubble}
      />
    </Container>
  );
}

export default TradeListsPage;
