import HelpBubble from '../HelpBubble/HelpBubble';
import MuleBubble from '../MuleBubble/MuleBubble';
import TrackerBubble from '../TrackerBubble/TrackerBubble';
import React, { useEffect, useState } from 'react';

interface BubbleContainerProps {
  pageKey?: string;
  helpText?: string;
  hideHelpBubble?: boolean;
  hideMuleBubble?: boolean;
  hideTrackerBubble?: boolean;
}

export default function BubbleContainer({
  pageKey,
  helpText,
  hideHelpBubble = false,
  hideMuleBubble = false,
  hideTrackerBubble = false,
}: BubbleContainerProps) {
  const [isOpen, setIsOpen] = useState(false);

  // Initialize localStorage values if not set
  useEffect(() => {
    if (localStorage.getItem('show_help_bubble') === null) {
      localStorage.setItem('show_help_bubble', '1');
    }
    if (localStorage.getItem('show_mule_bubble') === null) {
      localStorage.setItem('show_mule_bubble', '0');
    }
    if (localStorage.getItem('show_tracker_bubble') === null) {
      localStorage.setItem('show_tracker_bubble', '1');
    }
  }, []);

  const showHelpBubble =
    !hideHelpBubble && parseInt(localStorage.getItem('show_help_bubble') || '1', 10) === 1;
  const showMuleBubble =
    !hideMuleBubble &&
    parseInt(localStorage.getItem('show_mule_bubble') || '1', 10) === 1 &&
    localStorage.getItem('session_token') !== null;
  const showTrackerBubble =
    !hideTrackerBubble && parseInt(localStorage.getItem('show_tracker_bubble') || '1', 10) === 1;

  // Calculate visible bubbles for proper spacing
  const visibleBubbles = [
    showTrackerBubble && 'tracker',
    showMuleBubble && 'mule',
    showHelpBubble && 'help',
  ].filter(Boolean);

  // Get position for each bubble
  const getBubblePosition = (bubbleType: string) => {
    const index = visibleBubbles.indexOf(bubbleType);
    if (index === -1) return 0;
    return (visibleBubbles.length - 1 - index) * 72;
  };

  const bubbleContainerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: isOpen ? 9999 : 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '16px',
    width: isOpen ? 'unset' : 0,
    height: isOpen ? 'unset' : '62px',
  };

  // If no bubbles are visible, don't render anything
  if (!showHelpBubble && !showMuleBubble && !showTrackerBubble) {
    return null;
  }

  return (
    <div style={bubbleContainerStyle}>
      {showTrackerBubble && (
        <TrackerBubble
          hasBottomBubble={getBubblePosition('tracker') > 0}
          bottomBubblesCount={getBubblePosition('tracker') / 72}
        />
      )}
      {showMuleBubble && <MuleBubble hasBottomBubble={getBubblePosition('mule') > 0} />}
      {showHelpBubble && (
        <HelpBubble
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          pageKey={pageKey || ''}
          defaultText={helpText}
        />
      )}
    </div>
  );
}
