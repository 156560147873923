import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as CustomThemeProvider } from './context/ThemeContext';
import { useTheme } from './context/ThemeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './i18n';
import { Toaster } from 'react-hot-toast';

import 'assets/scss/material-kit-react.scss?v=1.10.0';

// pages for this product
import Components from 'views/Components/Components.js';
import NodePage from './views/NodePage/NodePage.js';
import LoginPage from 'views/LoginPage/LoginPage.js';
import SupplierItemPage from './views/ItemPage/SupplierItemPage';
import UsersNodePage from './views/UsersNodePage/UsersNodePage';
import MehlbotSalesHistoryPage from './views/MehlbotSalesHistoryPage/MehlbotSalesHistoryPage';
import PresetPage from './views/MehlbotPickitPage/PresetPage';
import SupplierPage from './views/SupplierPage/SupplierPage';
import PublicSearch from './views/PublicSearch/PublicSearch';
import NewItemPage from './views/NewItemPage/NewItemPage';
import StaffPage from './views/StaffPage/StaffNodePage';
import UserOverviewPage from './views/StaffPage/StaffUserPage';
import StaffOrderPage from './views/StaffOrderPage/StaffOrderPage';
import AnalyticsPage from './views/AnalyticsPage/AnalyticsPage';
import RareItemPage from 'views/ItemPage/RareItemPage';
import Layout from './views/Layout/Layout';
import NotFoundPage from './views/NotFoundPage/NotFoundPage';
import ProfilePage from './views/ProfilePage/ProfilePage';
import SettingsPage from 'views/SettingsPage/SettingsPage';
import OffersPage from './views/OffersPage/OffersPage';
import { ItemDetailPage } from './views/ItemDetailPage/ItemDetailPage';
import { PickitDetailPage } from './views/ProductDetailPage/ProductDetailPage';
import { HeaderLinksProvider } from 'components/Header/HeaderLinksContext';
import TradeListsPage from './views/TradeListsPage/TradeListsPage';
import OldItemPage from 'views/NewItemPage/OldItemPage';

const hist = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

const App = () => {
  const { isDarkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#142d4c',
        light: '#c45cd4',
        dark: '#385170',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ffa21a',
        light: '#ffb74d',
        dark: '#f57c00',
        contrastText: '#000000',
      },
      info: {
        main: isDarkMode ? '#ffffff' : '#142d4c',
        light: '#ffffff',
        dark: '#ffffff',
        contrastText: '#ffffff',
      },
      warning: {
        main: '#ff9800',
        light: '#ffb74d',
        dark: '#ffb74d',
        contrastText: '#ffffff',
      },
      background: {
        default: isDarkMode ? '#2d2d2d' : '#ffffff',
        paper: isDarkMode ? '#2d2d2d' : '#ffffff',
        header: '#212121',
        hoverNav: isDarkMode ? 'rgba(56, 81, 112, 0.1)' : '#ff9800',
      },
      text: {
        primary: isDarkMode ? '#ffffff' : '#000000',
        secondary: isDarkMode ? '#b3b3b3' : '#666666',
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            maxWidth: 'calc(100% - 48px) !important',
            '&.Mui-focused': {
              color: isDarkMode ? '#ffffff' : '#142d4c !important',
              maxWidth: 'calc(100% + 48px) !important',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            textAlign: 'left',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: '#6b6b6b #2b2b2b',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: '#2b2b2b',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: '#6b6b6b',
              minHeight: 24,
              border: '3px solid #2b2b2b',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#2b2b2b',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: isDarkMode ? '#ffffff' : '#142d4c',
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          input: {
            '&.MuiInputBase-root': {
              marginBottom: '6px',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#142d4c',
              opacity: isDarkMode ? '1' : '0.5',
              boxShadow: '0px 0px 8px rgba(255, 233, 34, 0.6)',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <HeaderLinksProvider>
        <CssBaseline />
        <BrowserRouter history={hist}>
          <Routes>
            <Route path="/" element={<Layout theme={theme} />}>
              <Route path="/components" element={<Components />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/tradelists" element={<TradeListsPage />} />
              <Route path="/node" element={<NodePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/sitems" element={<SupplierItemPage />} />
              <Route path="/users" element={<UsersNodePage />} />
              <Route path="/sales" element={<MehlbotSalesHistoryPage />} />
              <Route path="/preset" element={<PresetPage />} />
              <Route path="/supplier" element={<SupplierPage />} />
              <Route path="/items" element={<NewItemPage />} />
              <Route path="/olditems" element={<OldItemPage />} />
              <Route path="/staff" element={<StaffPage />} />
              <Route path="/staff/users" element={<UserOverviewPage />} />
              <Route path="/orders" element={<StaffOrderPage />} />
              <Route path="/offers" element={<OffersPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/prescreen" element={<RareItemPage />} />
              <Route path="/i/:id" element={<ItemDetailPage />} />
              <Route path="/p/:id" element={<PickitDetailPage />} />
              <Route index element={<PublicSearch />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          gutter={8}
          toastOptions={{
            style: {
              background: isDarkMode ? '#333' : '#fff',
              color: isDarkMode ? '#fff' : '#333',
            },
          }}
        />
      </HeaderLinksProvider>
    </ThemeProvider>
  );
};

// Get the root element
const container = document.getElementById('root');

// Create a root
const root = createRoot(container);

// Render your app
root.render(
  <CustomThemeProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </CustomThemeProvider>
);
